<template>
    <div v-if="loading">
      Loading...
    </div>
    <div v-else-if="items">

      <page-header title="Меню" class="mb-8">
        <template v-slot:right>
          <v-btn @click="save" color="success" :disabled="saving">
            <v-icon small left>mdi-content-save</v-icon>
            {{ saving ? 'Сохранение...' : 'Сохранить' }}
          </v-btn>
        </template>
      </page-header>

      <menu-editor v-model="items" />
    </div>
</template>

<script>
import tenantService from '@/services/tenant'
import MenuEditor from '@/components/Menu/MenuEditor.vue'
import PageHeader from '@/components/PageHeader.vue'

export default {
  components: {
    PageHeader,
    MenuEditor,
  },
  created () {
    return this.fetch()
  },
  data () {
    return {
      items: null,
      loading: false,
      saving: false
    }
  },
  methods: {
    async fetch () {
      let error
      this.loading = true
      try {
        const { items } = await tenantService.getWebmenu()
        this.items = items
      } catch (e) {
        error = e
      }
      this.loading = false
      if (error) {
        throw error
      }
    },
    async save () {
      let error
      this.saving = true
      try {
        await tenantService.updateWebmenu({ items: this.items })
      } catch (e) {
        error = e
      }
      this.saving = false
      if (error) {
        throw error
      }
    }
  }
}
</script>
