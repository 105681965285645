<template>
  <validation-observer v-slot="{ handleSubmit }">
    <div v-if="loading">Loading...</div>
    <div v-else-if="form">
      <page-header title="Основное" class="mb-8">
        <template v-slot:right>
          <v-btn
            color="success"
            :disabled="saving"
            @click="() => handleSubmit(save)"
          >
            {{ saving ? 'Сохранение...' : 'Сохранить' }}
          </v-btn>
        </template>
      </page-header>

      <v-row>
        <v-col>
          <locale-field
            component="v-text-field"
            v-model="form.meta.title"
            label="Название сайта"
            rules="required"
            outlined
            dense
            name="title"
            hide-details
          />
          <locale-field
            component="v-textarea"
            class="mt-4"
            auto-grow
            :rows="2"
            outlined
            rules="required"
            hide-details
            name="description"
            placeholder="Description"
            label="Описание"
            v-model="form.description"
            dense
          />
        </v-col>
        <v-col>
          <share-image-editor
            name="ogImage"
            v-bind="ogPreview"
            @input:image="form.openGraphImage = $event"
          />
        </v-col>
      </v-row>

      <div class="text-h5 mt-8">
        Tenant
      </div>
      <v-row>
        <v-col cols="6">
          <locale-field
            component="v-text-field"
            v-model="form.title"
            label="Tenant name"
            hide-details
            outlined
            dense
          />
        </v-col>
        <v-col cols="6">
          <locale-field
            component="v-text-field"
            v-model="form.shortTitleWithPlace"
            label="Tenant name with place"
            hide-details
            outlined
            dense
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="form.targetTenantName"
            name="targetTenantName"
            label="targetTenantName"
            outlined
            dense
            placeholder="targetTenantName"
          />
        </v-col>
      </v-row>

      <div class="text-h5">
        Локализация
      </div>
      <v-row>
        <v-col>
          Доступные локали
          <SettingsLocalesSelect v-model="form.locales" />
        </v-col>
        <v-col>
          Локаль по умолчанию
          <SettingsLangSelect v-model="form.defaultLocale" />
        </v-col>
        <v-col>
          Часовой пояс
          <SettingsTimezoneSelect v-model="form.timezone" />
        </v-col>
      </v-row>


      <div class="text-h5">
        Фичи
      </div>
      <v-row>
        <v-col>
          <v-checkbox
            v-model="form.features.banner.enabled"
            :label="`Баннер ${form.features.banner.enabled ? 'активен' : 'неактивен'  }`"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-checkbox
            v-model="form.grayscaleDoctorImages"
            label="Ч/б фотографии докторов"
          />
        </v-col>
      </v-row>

      <br>

      <div class="text-h5">
        Изображения
      </div>

      <v-row>
        <v-col cols="4">
          Favicon
          <image-select-provider v-model="form.favicon" v-slot="{ choose, remove }">
            <div class="d-flex align-center" style="border-radius: 8px; padding: 8px 12px; background: #f7f7f7">
              <img
                :src="form.favicon ? form.favicon.url : null"
                style="width: 32px; height: 32px; object-fit: cover; background-color: #eee"
              />
              <v-btn @click="remove" small icon title="Удалить" style="margin-left: 8px"><v-icon x-small>fa-close</v-icon></v-btn>
              <v-btn @click="choose" small style="margin: 0 0 0 auto">Выбрать</v-btn>
            </div>
          </image-select-provider>
        </v-col>
      </v-row>

      <v-row class="mb-12">
        <v-col :cols="6">
          <div class="mb-2">Лого в шапке</div>
          <image-select-provider v-model="form.logo" v-slot="{ choose, remove }">
            <div>
              <image-requirements ratio="16/9" class="caption"></image-requirements>
              <img :src="form.logo ? form.logo.url : null" style="max-width: 100%; display: block">
              <div class="d-flex justify-space-between mt-2">
                <v-btn small @click="choose">Выбрать</v-btn>
                <v-btn small @click="remove" v-if="form.logo">Удалить</v-btn>
              </div>
            </div>
          </image-select-provider>
        </v-col>
        <v-col cols="6">
          <div class="mb-2">
            Заставка главной страницы
          </div>
          <image-select-provider v-model="form.imageOnMain" v-slot="{ choose, remove }">
            <div>
              <image-requirements size="от 1920px" class="caption"></image-requirements>
              <img :src="form.imageOnMain ? form.imageOnMain.url : null" style="max-width: 100%; display: block">
              <div class="d-flex justify-space-between mt-2">
                <v-btn small @click="choose">Выбрать</v-btn>
                <v-btn small @click="remove" v-if="form.logo">Удалить</v-btn>
              </div>
            </div>
          </image-select-provider>
        </v-col>
      </v-row>






      <v-row>
        <v-col>
          Доступные локали
          <SettingsLocalesSelect v-model="form.locales" />
        </v-col>
        <v-col>
          Локаль по умолчанию
          <SettingsLangSelect v-model="form.defaultLocale" />
        </v-col>
        <v-col>
          Часовой пояс
          <SettingsTimezoneSelect v-model="form.timezone" />
        </v-col>
      </v-row>

      <br>

      <div class="text-h5">
        Изображения
      </div>
      <v-row>
        <v-col>
          og image
          <settings-image-picker
            name="ogImage"
            v-model="form.openGraphImage"
          />
        </v-col>
        <v-col>
          Лого в шапке
          <settings-image-picker
            name="logo"
            v-model="form.logo"
          />
        </v-col>
        <v-col>
          Заставка главной страницы
          <settings-image-picker
            name="imageOnMain"
            v-model="form.imageOnMain"
          />
        </v-col>
        <v-col>
          Фавикон
          <settings-image-picker
            style="width: 128px; height: 128px"
            name="favicon"
            v-model="form.favicon"
          />
        </v-col>
      </v-row>

      <v-row class="mt-8">
        <v-col>
          <div class="text-h5">
            Палитра
          </div>

          <v-menu :close-on-content-click="false">
            <template #activator="{ on, attrs }">
              <div class="d-flex" style="grid-gap: 12px">
                <span>Accent color</span>
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :style="{ color: form.colorVariables.accentColor }"
                >fa-circle</v-icon>
              </div>
            </template>
            <v-color-picker
              mode="hexa"
              :value="form.accentColor"
              @update:color="({ hex }) => form.colorVariables.accentColor = hex"
            />
          </v-menu>
        </v-col>
      </v-row>
    </div>
  </validation-observer>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
import formValueMixin from './formValueMixin'
import SettingsImagePicker from './SettingsImagePicker.vue'
import SettingsLangSelect from './SettingsLangSelect.vue'
import SettingsTimezoneSelect from './SettingsTimezoneSelect.vue'
import SettingsLocalesSelect from './SettingsLocalesSelect.vue'
import LocaleField from '../Locale/LocaleField.vue'
import tenantService from '@/services/tenant'
import ShareImageEditor from '@/components/Share/ShareImageEditor.vue'
import ImageSelect from '@/components/elements/image-select.vue'
import ImageSelectProvider from '@/components/elements/image-select-provider.vue'

export default {
  mixins: [
    formValueMixin()
  ],
  components: {
    ImageSelect,
    ImageSelectProvider,
    ShareImageEditor,
    PageHeader,
    LocaleField,
    SettingsImagePicker,
    SettingsLangSelect,
    SettingsTimezoneSelect,
    SettingsLocalesSelect
  },
  data () {
    return {
      loading: false,
      saving: false,
      error: null,
      form: null
    }
  },
  mounted () {
    return this.fetch()
  },
  computed: {
    ogPreview () {
      const {
        title,
        description,
        openGraphImage: image,
      } = this.form
      return {
        title: title?.ru,
        description: description?.ru,
        image
      }
    }
  },
  methods: {
    async fetch () {
      this.loading = true
      try {
        const settings = await tenantService.getAll()
        this.form = settings
      } catch (e) {
        this.error = e
      }
      this.loading = false
    },
    async save () {
      this.saving = true
      try {
        const dto = tenantService.toDto(this.form)
        console.log(dto)
        await tenantService.update(dto)
      } catch (e) {
        console.error(e)
      }
      this.saving = false
    }
  }
}
</script>
